import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert'; // Import SweetAlert
import './ManagerUsers.css';
import myImage from './Visual Planet.png';

import Modal from 'react-modal'; // Import the Modal component

function CategoryList({ categories, onCategoryClick, onDeleteCategory }) {
  return (
    <div className="container-fluid">
      <h2 className="bus">Categories</h2>
      <div className='table-responsive'>

      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={index}>
              <td>{category.Category}</td>
              <td>
                <button onClick={() => onCategoryClick(category)} className='btngreen'>Edit</button>
                <button onClick={() => onDeleteCategory(category)} className='btndelete'>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
              
      </div>
    </div>
  );
}

function Managecatpo() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://appbackend.vpserver.online/admin/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setNewCategoryName(category.Category);
    setEditMode(true);
    setModalIsOpen(true); // Open the modal when clicking "Edit"

  };

  const handleUpdateCategory = async () => {
    try {
      await axios.put(`https://appbackend.vpserver.online/admin/categories/${selectedCategory.id}`, {
        Category: newCategoryName,
      });
      console.log('Category updated successfully');
      setSelectedCategory(null);
      setNewCategoryName('');
      setEditMode(false);
      // Refresh categories
      refreshCategories();
      setModalIsOpen(false); // Close the modal after updating

      // Show sweet alert on success
      swal("Updated!", "Category updated successfully!", "success");
    } catch (error) {
      console.error('Error updating category:', error);
      // Show sweet alert for error
      swal("Error!", "Something went wrong!", "error");
    }
  };

  const handleDeleteCategory = async (category) => {
    // Show confirmation dialog before deleting
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.delete(`https://appbackend.vpserver.online/admin/categories/${category.id}`);
          console.log('Category deleted successfully');
          // Refresh categories
          refreshCategories();
          // Show sweet alert on success
          swal("Deleted!", "Category deleted successfully!", "success");
        } catch (error) {
          console.error('Error deleting category:', error);
          // Show sweet alert for error
          swal("Error!", "Something went wrong!", "error");
        }
      } else {
        // Show cancel message if user cancels delete operation
        swal("Cancelled!", "Category deletion cancelled!", "info");
      }
    });
  };

  const refreshCategories = async () => {
    try {
      const response = await axios.get('https://appbackend.vpserver.online/admin/categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      // Show sweet alert for error
      swal("Error!", "Something went wrong!", "error");
    }
  };

  return (
    <>
                  <div className='admin-container addbg'>

    <div className='admin-header '>
    </div>
    <div className="container">
      <CategoryList categories={categories} onCategoryClick={handleCategoryClick} onDeleteCategory={handleDeleteCategory} />
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="custom-modal" overlayClassName="custom-overlay">
      {selectedCategory && editMode && (
        <div className="details">
          <h2 className="center bus">Edit Category</h2>
          <div className="input-field">
            <label>Category Name:</label>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
            />
          </div>
          <button className="update-button" onClick={handleUpdateCategory}>Update Category</button>
        </div>
      )}
      </Modal>
    </div>
    </div>
    </>
  );
}

export default Managecatpo;
