import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import myImage from './Visual Planet.png';
import 'react-datepicker/dist/react-datepicker.css';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2';
import './Today.css'; 

function BusinessList({ onBusinessClick, businesses }) {
  // Extract numeric part from Business ID and sort in descending order
  const sortedBusinesses = [...businesses].sort((a, b) => {
    const idA = parseInt(a.id.split('-')[1], 10);
    const idB = parseInt(b.id.split('-')[1], 10);
    return idB - idA;
  });

  return (
    <div className="container-fluid">
      <h2 className='bus'>Businesses</h2>
      <div className="table-responsive">
        <table className="table business-table">
          <thead>
            <tr>
              <th>Business Name</th>
              <th>Next Meeting</th>
              <th>Date of Project</th>
              <th>Spanco</th>
              <th>Phone Number</th>
              <th>Mail ID</th>
              <th>Business ID</th>
            </tr>
          </thead>
          <tbody>
            {sortedBusinesses.map((business, index) => (
              <tr key={index} onClick={() => onBusinessClick(business)}>
                <td>{business.businessname}</td>
                <td>{formatDate(business.dateofnextmeeting)}</td>
                <td>{formatDate(business.dateofproject)}</td>
                <td>{business.spanco}</td>
                <td>{business.contactnumber}</td>
                <td>{business.email}</td>
                <td>{business.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.log("Invalid date:", dateString);
    return 'Invalid date';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}/${month}/${year}`;
}
function BusinessDetails({ selectedBusiness }) {
  return (
    <div className="centered">
      <h2 className='bus'>Business Details</h2>
      <table>
        <tbody>
          <tr>
            <td className='bus'>Business Name:</td>
            <td>{selectedBusiness}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Today() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('loginlocation');
  const navigate = useNavigate();
  const filterRef = useRef(null); 
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [spanco, setSpanco] = useState('');
  const [BusinessID, setBusinessID] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [product, setProduct] = useState(""); // Add state for the selected product

  const [businesses, setBusinesses] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [productCounts, setProductCounts] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [spancoCount, setSpancoCount] = useState({
    Suspect: 0,
    Prospect: 0,
    Approach: 0,
    Negotiation: 0,
    Close: 0,
    Order: 0,
    Omission: 0
  });

  const [values, setValues] = useState({
    loginLocation: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [fullSuggestions, setFullSuggestions] = useState([]);  // Store full list of suggestions
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [inputFieldsVisible, setInputFieldsVisible] = useState(false);

  const [suggestions, setSuggestions] = useState([]);
  const [showFilters, setShowFilters] = useState(false); 
  const handleBusinessClick = (business) => {
    const businessname = encodeURIComponent(business.businessname);
    const id = encodeURIComponent(business.id);
    navigate(`/form?loginlocation=${username}&businessname=${businessname}&id=${id}`);
    setSelectedBusiness(business);
  };
  const handleSpancoChange = (event) => {
    setSpanco(event.target.value);
  };
  const handleFromDateChange = (date) => {
        setSelectedFromDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));

  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleToDateChange = (date) => {
    setSelectedToDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
  };
  const handleToday = () => {
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
  };
  const handleSearch = async () => {
    try {
      const formattedFromDate = selectedFromDate ? selectedFromDate.toISOString().split('T')[0] : '';
      const formattedToDate = selectedToDate ? selectedToDate.toISOString().split('T')[0] : '';
      const response = await axios.get(`https://appbackend.vpserver.online/today?loginlocation=${username}&spanco=${spanco}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&businessname=${businessName}&id=${BusinessID}&contactnumber=${PhoneNumber}&product=${product}`);
      setBusinesses(response.data);
      updateSpancoCount(response.data);
    } catch (error) {
      console.error('Error fetching business names:', error);
    }
   
  };
  const updateSpancoCount = (businessData) => {
    const spancoCounts = { ...spancoCount };
    Object.keys(spancoCounts).forEach(key => {
      spancoCounts[key] = 0;
    });
    businessData.forEach(business => {
      spancoCounts[business.spanco]++;
    });
    setSpancoCount(spancoCounts);
  };
  const handleOpenModal = () => {
    setModalIsOpen(true);
    setShowFilters(false); 
    const spancoCounts = {};
    businesses.forEach(business => {
      if (business.spanco in spancoCounts) {
        spancoCounts[business.spanco]++;
      } else {
        spancoCounts[business.spanco] = 1;
      }
    });
    setSpancoCount(spancoCounts);
  };
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  const handleSuggestionClick = (suggestion) => {
    setBusinessName(suggestion);
    setShowSuggestions(false);
  };
  const handleProductClick = (productName) => {
    setProduct(productName);
    handleSearch(productName);
  };

  const handleUpdateMeetingDate = async () => {
    try {
      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
      await axios.put('https://appbackend.vpserver.online/updateMeetingDate', {
        date: formattedDate
      });
      handleSearch();
      Swal.fire({
        icon: 'success',
        title: 'Meeting Date Updated!',
        text: 'The meeting date has been successfully updated.',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error updating meeting date:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong while updating the meeting date. Please try again later.',
        confirmButtonText: 'OK'
      });
    }
  };
  const handleBusinessIDChange = (event) => {
    setBusinessID(event.target.value);
  };

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setBusinessName(inputValue);
    setShowSuggestions(inputValue.length > 0);

    try {
      const response = await axios.get(`https://localhost:5000/suggestBusinessNames?loginlocation=${username}&businessname=${inputValue}`);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching business name suggestions:', error);
    }
  };

  useEffect(() => {
    const fetchProductCounts = async () => {
      try {
        const response = await axios.get(`https://appbackend.vpserver.online/adminproductcountstoday?loginlocation=${username}`);
        setProductCounts(response.data);
      } catch (error) {
        console.error('Error fetching product counts:', error);
      }
    };
  
    if (username) { // Ensure username is defined
      fetchProductCounts();
    }
  }, [username]); // Add username as dependency to refetch when it changes
  
  useEffect(() => {
    handleSearch();
  }, [productCounts]); // Add productCounts as dependency to refetch when it changes
  

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    // Function to handle click outside of filter to close it
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target) && !event.target.closest('.Todayfilter-toggle')) {
        setShowFilters(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleCompanyChange = (event) => {
    setBusinessName(event.target.value);
  };

  const CustomInputF = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <button className="custom-input btnblue" onClick={onClick} ref={ref}>
      {value || 'From'} <FontAwesomeIcon icon={faCalendarAlt} />
    </button>
  ));
  const CustomInputT = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <button className="custom-input btnblue" onClick={onClick} ref={ref}>
      {value || 'To'} <FontAwesomeIcon icon={faCalendarAlt} />
    </button>
  ));
  return (
    <>
      <div className="Today centered">
        <div>
          <div className='admin-header'>
            <img src={myImage} alt="My Image" className="admin-panel-image" />
          </div> 
          <div className="product-buttons">
        {Object.entries(productCounts).map(([productName, count]) => (
          <button className='AfollowbuttonP' key={productName} onClick={() => handleProductClick(productName)}>
            {productName} - {count}
          </button>
        ))}
        <button className='AfollowbuttonP' onClick={() => handleProductClick("")}>All</button>
      </div>
        </div>
        <div className='intext'>SPANCOO</div>
<div className="input_AdminFollow">
<button className={spanco === "" ? "selected" : ""} onClick={() => {handleSearch(); setSpanco("");}} >
          All {spancoCount[""] === undefined ? "" : `${spancoCount[""]}`}
        </button>
        <button className={`suspect ${spanco === "Suspect" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Suspect");}}>
          S {spancoCount["Suspect"] === undefined ? "(0)" : `(${spancoCount["Suspect"]})`}
        </button>
        <button className={`prospect ${spanco === "Prospect" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Prospect");}}>
          P {spancoCount["Prospect"] === undefined ? "(0)" : `(${spancoCount["Prospect"]})`}
        </button>
        <button className={`approach ${spanco === "Approach" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Approach");}}>
          A {spancoCount["Approach"] === undefined ? "(0)" : `(${spancoCount["Approach"]})`}
        </button>
        <button className={`negotiation ${spanco === "Negotiation" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Negotiation");}}>
          N {spancoCount["Negotiation"] === undefined ? "(0)" : `(${spancoCount["Negotiation"]})`}
        </button>
        <button className={`closed ${spanco === "Close" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Close");}}>
          C {spancoCount["Close"] === undefined ? "(0)" : `(${spancoCount["Close"]})`}
        </button>
        <button className={`order ${spanco === "Order" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Order");}}>
          O {spancoCount["Order"] === undefined ? "(0)" : `(${spancoCount["Order"]})`}
        </button>
        <button className={`omission ${spanco === "Omission" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Omission");}}>
          O {spancoCount["Omission"] === undefined ? "(0)" : `(${spancoCount["Omission"]})`}
        </button>
<div className=''>
  <input className="input_AdminFollow" type="text" value={businessName} placeholder='Enter Business Name' onChange={handleCompanyChange}>
  </input>

  <input className="input_AdminFollow" type="text" placeholder='Enter Bussiness ID' value={BusinessID} onChange={handleBusinessIDChange} />
  
  <input className="input_AdminFollow" type="text" placeholder='Enter Phone Number' value={PhoneNumber} onChange={handlePhoneNumberChange} />
  </div>

  <div className=''>
  <DatePicker
          selected={selectedFromDate}
          onChange={handleFromDateChange}
          customInput={<CustomInputF className="CustomInputF" placeholder="FD" />}
          popperPlacement="bottom-start"
        />
        <DatePicker
          selected={selectedToDate}
          onChange={handleToDateChange}
          customInput={<CustomInputT className="CustomInputT" placeholder="Select Date" />}
          popperPlacement="bottom"
        />
        
  </div>


  <button className='inlabel Afollowbuttonsearch' onClick={() => {handleSearch();handleCloseModal();}}>Search  </button>
  <div>
  <button onClick={handleUpdateMeetingDate} className='btnblue'>Update Meeting Date</button>

  </div>

</div>

        {showFilters && (
          <div ref={filterRef} className="Todayfilter-container">
            <div className='inbody'>
              <div className='inbtn'>
                <label className='intext'>From Date:</label>
                <DatePicker selected={selectedFromDate} onChange={handleFromDateChange} />
                <label className='intext'>To Date:</label>
                <DatePicker selected={selectedToDate} onChange={handleToDateChange} />
                <label className='intext'>Spanco:</label>
                <select onChange={handleSpancoChange}>
                  <option value="">All</option>
                  <option value="Suspect">Suspect</option>
                  <option value="Prospect">Prospect</option>
                  <option value="Approach">Approach</option>
                  <option value="Negotiation">Negotiation</option>
                  <option value="Close">Close</option>
                  <option value="Order">Order</option>
                  <option value="Omission">Omission</option>
                </select>
                <label className='intext'>Business Name:</label>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <input
                    type="text"
                    value={businessName}
                    onChange={handleInputChange}
                  />
                  <ul className={`suggestions ${showSuggestions ? 'show' : 'hide'}`}>
                    {suggestions.map((suggestion, index) => (
                      <li key={index} onClick={() => handleSuggestionClick(suggestion)} className="suggestion-item">
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                </div>
                <button onClick={handleSearch} className='btngreen Mbtm'>Search</button>


                <button onClick={handleOpenModal} className='btnblue'>View Spanco Count</button>
                <button onClick={handleUpdateMeetingDate} className='btnblue'>Update Meeting Date</button>
              </div>
            </div>
          </div>
        )}
        <BusinessList onBusinessClick={handleBusinessClick} businesses={businesses} />
        {selectedBusiness && <BusinessDetails selectedBusiness={selectedBusiness} />}
        <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} className="Modal">
          <h2>Spanco Counts</h2>
          <div>
            {Object.entries(spancoCount).map(([spancoType, count]) => (
              <p key={spancoType}>
                {spancoType}: {count}
              </p>
            ))}
          </div>
          <button onClick={handleCloseModal}>Close</button>
        </Modal>
      </div>
    </>
  );
}

export default Today;
