import './App.css';
import Login from './Login';
import Signup from './Signup';
import Home from './Home';
import NewOrderForm from './New.js';
import FollowUp from './FollowUp.js';
import Today from './Today.js';
import Spanco from './Spanco.js';
import Form from './Form.js';
import Demo from './demo.js';
import Admin from './Admin.js';
import Add from './Add.js';
import AdminFollow from './AdminFollow.js';
import AdminForm from './AdminForm.js';
import Manageusers from './Manageusers.js';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import AddManage from './AddManage.js';
import UserAdd from './UserAdd.js';
import ManageOp from './MnageOp.js';
import Bill from './Bill.js';
import Managecatpo from './Managecatpo.js';
import ManageProducts from './ManageProducts.js';
import Choosecatpo from './Choosecatpo.js';
import ModalPage from './ModalPage.js';
import Rep from './Rep.js';
import AddPatient  from './AddPatient.js';
import ManagePositions from './ManagePositions.js';
import AdminNew from './AdminNew.js'
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={< Login />} />
        <Route path='/signup' element={<PrivateRoute component={Signup} />} />
        <Route path='/home' element={<PrivateRoute component={Home} />} />
        <Route path='/new' element={<PrivateRoute component={NewOrderForm} />} />
        <Route path='/followup' element={<PrivateRoute component={FollowUp} />} />
        <Route path='/today' element={<PrivateRoute component={Today} />} />
        <Route path='/spanco' element={<PrivateRoute component={Spanco} />} />
        <Route path='/form' element={<PrivateRoute component={Form} />} />
        <Route path='/demo' element={<PrivateRoute component={Demo} />} />
        <Route path='/admin' element={<PrivateRoute component={Admin} />} />
        <Route path='/add' element={<PrivateRoute component={Add} />} />
        <Route path='/adminfollow' element={<PrivateRoute component={AdminFollow} />} />
        <Route path='/adminform' element={<PrivateRoute component={AdminForm} />} />
        <Route path='/manageusers' element={<PrivateRoute component={Manageusers} />} />
        <Route path='/addmanage' element={<PrivateRoute component={AddManage} />} />
        <Route path='/UserAdd' element={<PrivateRoute component={UserAdd} />} />
        <Route path='/manageop' element={<PrivateRoute component={ManageOp} />} />
        <Route path='/bill' element={<PrivateRoute component={Bill} />} />
        <Route path='/managecatpo' element={<PrivateRoute component={Managecatpo} />} />
        <Route path='/manageproduct' element={<PrivateRoute component={ManageProducts} />} />
        <Route path='/choosecatpo' element={<PrivateRoute component={Choosecatpo} />} />
        <Route path="/modal" element={<PrivateRoute component={ModalPage} />} />
        <Route path="/managepositions" element={<PrivateRoute component={ManagePositions} />} />
        <Route path="/Rep" element={<PrivateRoute component={Rep} />} />
        <Route path="/addp" element={<PrivateRoute component={AddPatient} />} />
        <Route path="/adminnew" element={<PrivateRoute component={AdminNew} />} />

      </Routes>
    </Router>
  );
}

export default App;
