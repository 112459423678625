import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component }) => {
  const isAuthenticated = !!sessionStorage.getItem('sessionId'); // Check if session ID exists

  return isAuthenticated ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
