import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Home.css';
function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('loginlocation');
  const country = searchParams.get('country');
  const state = searchParams.get('state');
  const district = searchParams.get('district');
  const area = searchParams.get('area');

  const handleLogout = () => {
    sessionStorage.removeItem('sessionId'); // Clear the session ID from sessionStorage
    navigate('/'); // Redirect to login page
  };
  const handleShare = () => {
    const shareData = {
      title: 'Check this out',
      text: 'Have a look at this demo!',
      url: 'https://youtu.be/7CASfFoaU5s?si=qZJs8GMKGJh9C8Kr'
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Successfully shared'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback: copy to clipboard
      navigator.clipboard.writeText(shareData.url)
        .then(() => alert('Link copied to clipboard!'))
        .catch((error) => console.error('Error copying to clipboard:', error));
    }
  };
  return (
    <>
      <div className='admin-container2'>
        <div className='admin-panel'>
          <div className='admin-buttons'>
            <Link
              to={`/new?loginlocation=${username}&country=${country}&state=${state}&district=${district}&area=${area}`}
              className='btn-default'
            >
              NEW
            </Link>
            <Link
              to={`https://www.visualplanet.in/demo`}
              className='btn-default'
              target="_blank"
              rel="noopener noreferrer"
            >
              DEMO
            </Link>
            <Link
              to={`/today?loginlocation=${username}&country=${country}&state=${state}&district=${district}&area=${area}`}
              className='btn-default'
            >
              FOLLOW UP
            </Link>
            <Link onClick={handleShare} className='btn-default'>
              video
            </Link>
            <Link
              to={`https://drive.google.com/file/d/17-YApPnkByeYXPs4zvNyldy8WnlAaFpQ/view?usp=sharing`}
              className='btn-default'
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF
            </Link>
            <button onClick={handleLogout} className='btn btn-danger'>
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
  
}
export default Home;
