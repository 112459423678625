// Add.js

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import './Add.css';

function Add() {
  const [values, setValues] = useState({
    category: '',
    product: '',
    productPrice: '',
    productDiscount: '',
    note: '',
    image: null
  });
  const [position, setPosition] = useState('');
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleAddPosition = async (event) => {
    event.preventDefault();

    if (!position) {
      setErrors({ position: 'Position is required' });
      return;
    }

    try {
      const response = await axios.get(`https://appbackend.vpserver.online/checkposition/${position}`);
      if (response.data.exists) {
        setErrors({ position: 'Position already exists' });
        return;
      }
    } catch (error) {
      console.error('Error checking position:', error);
      return;
    }

    const positionData = {
      id: uuidv4(),
      position: position
    };

    axios.post('https://appbackend.vpserver.online/addposition', positionData)
      .then(res => {
        console.log('Position added successfully');
        setPosition('');
        setSuccessMessage('Position added successfully');
        setValues({ ...values, category: '', product: '', productPrice: '', productDiscount: '' });

        // Display SweetAlert notification
        Swal.fire({
          icon: 'success',
          title: 'Position Added',
          text: 'Position added successfully!'
        });
      })
      .catch(err => console.log(err));
  }

  const handleUpload = () => {
    const formdata = new FormData();
    formdata.append('image', file);
    axios.post('https://appbackend.vpserver.online/upload', formdata)
      .then(res => {
        if (res.data.Status === "Success") {
          console.log("Succeeded")
        } else {
          console.log("Failed");
        }
      })
      .catch(err => console.log(err))

  };

  const handleFile = (e) => {
    setFile(e.target.files[0])
  };

  const handleAddCategory = async (event) => {
    event.preventDefault();

    if (!values.category) {
      setErrors({ category: 'Category is required' });
      return;
    }

    try {
      const response = await axios.get(`https://appbackend.vpserver.online/checkcategory/${values.category}`);
      if (response.data.exists) {
        setErrors({ category: 'Category already exists' });
        return;
      }
    } catch (error) {
      console.error('Error checking category:', error);
      return;
    }

    const categoryData = {
      id: uuidv4(),
      category: values.category
    };

    axios.post('https://appbackend.vpserver.online/addcategory', categoryData)
      .then(res => {
        console.log('Category added successfully');
        setSuccessMessage('Category added successfully');
        setValues({ ...values, category: '', product: '', productPrice: '', productDiscount: '', note: '' });

        // Display SweetAlert notification
        Swal.fire({
          icon: 'success',
          title: 'Category Added',
          text: 'Category added successfully!'
        });
      })
      .catch(err => console.log(err));
  }

  const handleImageChange = (event) => {
    setValues({ ...values, image: event.target.files[0] });
  }

  const triggerFileInput = () => {
    fileInputRef.current.click();
  }

  const handleAddProduct = async (event) => {
    event.preventDefault();

    if (!values.product || !values.productPrice || !values.productDiscount) {
      setErrors({ product: 'Product, Product Price, and Product Discount are required' });
      return;
    }

    try {
      const response = await axios.get(`https://appbackend.vpserver.online/checkproduct/${values.product}`);
      if (response.data.exists) {
        setErrors({ product: 'Product already exists' });
        return;
      }
    } catch (error) {
      console.error('Error checking product:', error);
      return;
    }

    const productData = {
      id: uuidv4(),
      product: values.product,
      productPrice: values.productPrice,
      productDiscount: values.productDiscount,
      productNote: values.note
    };

    axios.post('https://appbackend.vpserver.online/addproduct', productData)
      .then(res => {
        console.log('Product added successfully');
        setSuccessMessage('Product added successfully');
        setValues({ ...values, category: '', product: '', productPrice: '', productDiscount: '', note: '' });

        // Display SweetAlert notification
        Swal.fire({
          icon: 'success',
          title: 'Product Added',
          text: 'Product added successfully!'
        });
      })
      .catch(err => console.log(err));
  }

  const handleInputChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
    setErrors({}); // Clear any previous errors when the user starts typing
  }

  const handleDefaultNote = () => {
    const defaultNote =
      "01. Project will be Finished & Submited with 7 days once the form is filled\n" +
      "02. 3 Free Updation (Change of Phone number, Content, email ID, No Change in Designs)\n" +
      "03. 50% of Amount have to be dispersed on the day of Project\n" +
      "04. No Change in the Website Template as it is totally free of Cost\n" +
      "05. Drone Shots has to be checked on the date of Flying and add your shots on the day of flying & for no reasons we wilt do the drone shots will be taken on the fixed package";

    setValues({ ...values, note: defaultNote });
  };

  return (
    <div className='addbg'>
    <div className='container'>
      <div className='form-container'>
        <h1 className='form-heading bus'></h1>
        <form className='form'>
          <div className='form-group'>
            <label htmlFor="category">Category</label>
            <input type="text" placeholder='Enter Category' name='category'
              value={values.category} onChange={handleInputChange} className='form-control' />
            {errors.category && <span className='text-danger'>{errors.category}</span>}
          </div>
          <button type='submit' className='btngreen' onClick={handleAddCategory}>Add Category</button>

          <div className='spacer'></div>

          <div className='form-group'>
            <label htmlFor="product">Product</label>
            <input type="text" placeholder='Enter Product' name='product'
              value={values.product} onChange={handleInputChange} className='form-control' />
            {errors.product && <span className='text-danger'>{errors.product}</span>}
          </div>
          <div className='form-group'>
            <label htmlFor="productPrice">Product Price</label>
            <input type="text" placeholder='Enter Product Price' name='productPrice'
              value={values.productPrice} onChange={handleInputChange} className='form-control' />
            {errors.productPrice && <span className='text-danger'>{errors.productPrice}</span>}
          </div>
          <div className='form-group'>
            <label htmlFor="productDiscount">Product Discount</label>
            <input type="text" placeholder='Enter Product Discount' name='productDiscount'
              value={values.productDiscount} onChange={handleInputChange} className='form-control' />
            {errors.productDiscount && <span className='text-danger'>{errors.productDiscount}</span>}
          </div>
          <div className='form-group'>
            <label htmlFor="note">Note</label>
            <textarea
              placeholder='Enter Note'
              name='note'
              value={values.note}
              onChange={handleInputChange}
              className='form-control'
              rows={5}
            />
            {errors.note && <span className='text-danger'>{errors.note}</span>}
          </div>

          <button type="button" className="btn btn-primary" onClick={handleDefaultNote}>Default Note</button>
          <button className='btngreen' onClick={handleAddProduct}>Add Product</button>

          <div className='spacer'></div>

          <div className='form-group'>
            <label htmlFor="position">Position</label>
            <input
              type="text"
              placeholder='Enter Position'
              name='position'
              value={position}
              onChange={handlePositionChange}
              className='form-control'
            />
            {errors.position && <span className='text-danger'>{errors.position}</span>}
          </div>
          <button type='submit' className='btngreen' onClick={handleAddPosition}>Add Position</button>

          {successMessage && <div className='text-danger mt-1 notification'>{successMessage}</div>}
        </form>
      </div>
    </div>
    </div>
  );
}

export default Add;
