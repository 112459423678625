import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import './AdminFollow.css';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import myImage from './Visual Planet.png';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function BusinessList({ onBusinessClick, businesses }) {
  const handleExportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'business_data_detailed_summary';

    const spancoCounts = {};
    businesses.forEach(business => {
      spancoCounts[business.spanco] = (spancoCounts[business.spanco] || 0) + 1;
    });

    const summaryItems = businesses.map(business => ({
      ...business,
      'Spanco Count': spancoCounts[business.spanco] > 1 ? `${business.spanco} (${spancoCounts[business.spanco]})` : business.spanco
    }));

    const ws = XLSX.utils.json_to_sheet(summaryItems);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };
  const navigate = useNavigate();


  // Function to format dates
  const formatDate = (date) => {
    if (!date) return '-';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('sessionId'); // Clear the session ID from sessionStorage
    navigate('/'); // Redirect to login page
  };

  // Function to compare timestamps for sorting
  const compareTimestampsDescending = (a, b) => {
    const timestampA = new Date(a.timestamp);
    const timestampB = new Date(b.timestamp);
    return timestampB - timestampA;
  };

  // Sort businesses by timestamp in descending order
  const sortedBusinesses = [...businesses].sort(compareTimestampsDescending);

  return (
    <div className="container-fluid">
      <h2 className='bus'></h2>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Business Name</th>
              <th>Next Meeting</th>
              <th>Date of Project</th>
              <th>Spanco</th>
              <th>Phone Number</th>
              <th>Mail ID</th>
              <th>Contact Status</th>
              <th>Business ID</th>

            </tr>
          </thead>
          <tbody>
            {sortedBusinesses.map((business, index) => (
              <tr key={index} onClick={() => onBusinessClick(business)}>
                <td className="hoverable" style={{ position: 'relative' }}>
                  <div className="business-name">{business.businessname}</div>
                  <div className="hover-info">
                    Next Meeting: {formatDate(business.dateofnextmeeting)}<br />
                    Spanco: {business.spanco}<br />
                    Contact Status: <span style={{ color: business.contact_status === 'Not Contacted' ? 'red' : 'rgb(54, 233, 4)' }}>{business.contact_status}</span>
                  </div>
                </td>
                <td>{formatDate(business.dateofnextmeeting)}</td>
                <td>{formatDate(business.dateofproject)}</td>
                <td>{business.spanco}</td>
                <td>{business.contactnumber}</td>
                <td>{business.email}</td>
                <td style={{ color: business.contact_status === 'Not Contacted' ? 'red' : 'rgb(54, 233, 4)' }}>
                  {business.contact_status}
                </td>
                <td>{business.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='Cent'>
          
          <button className='inlabel Afollowbuttonsearch' onClick={handleExportToExcel}>Export to Excel </button>
          <button onClick={handleLogout} className=' btn-danger'>Logout</button>

        </div>
      </div>
    </div>
  );
}


function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}/${month}/${year}`;
}

function BusinessDetails({ selectedBusiness }) {
  return (
    <div className="business-details">
      <h2>Business Details</h2>
      <p><strong>Name:</strong> {selectedBusiness.businessname}</p>
      <p><strong>Next Meeting:</strong> {formatDate(selectedBusiness.dateofnextmeeting)}</p>
      <p><strong>Spanco Stage:</strong> {selectedBusiness.spanco}</p>
      <p><strong>Phone Number:</strong> {selectedBusiness.email}</p>
      <p><strong>Business ID:</strong> {selectedBusiness.id}</p>
      {/* Add more details as needed */}
    </div>
  );
}

function Rep() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('loginlocation');
  const id = searchParams.get('id');

  const navigate = useNavigate();

  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [spanco, setSpanco] = useState('');
  const [locationOptions, setLocationOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [Location, setLocation] = useState('');
  const [Country, setCountry] = useState('');
  const [State, setState] = useState('');
  const [District, setDistrict] = useState('');
  const [Area, setArea] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [BusinessID, setBusinessID] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [BusinessName, setBusinessName] = useState('');
  const [productCounts, setProductCounts] = useState({});
  const [product, setProduct] = useState(""); // Add state for the selected product

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [spancoCount, setSpancoCount] = useState({
    Suspect: 0,
    Prospect: 0,
    Approach: 0,
    Negotiation: 0,
    Close: 0,
    Order: 0,
    Omission: 0
  });
  const [values, setValues] = useState({
    loginLocation: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [fullSuggestions, setFullSuggestions] = useState([]);  // Store full list of suggestions
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [inputFieldsVisible, setInputFieldsVisible] = useState(false);

  const filterRef = useRef(null);

  const handleBusinessClick = (business) => {
    const businessname = encodeURIComponent(business.businessname);
    const id = encodeURIComponent(business.id);
    navigate(`/adminform?loginlocation=${username}&businessname=${businessname}&id=${id}`);
    setSelectedBusiness(business);
  };


  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };


  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    // Reset state and district options when country changes
    setState('');
    setDistrict('');
        setArea('');

    // Fetch states based on selected country
    fetchStates(selectedCountry);
  };

  // const handleStateChange = (event) => {
  //   setState(event.target.value);
  // };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);
    // Reset state and district options when country changes
    setDistrict('');
    setArea('');
    // Fetch states based on selected country
    fetchDistrict(selectedState);
  };

  useEffect(() => {
    axios.get('https://appbackend.vpserver.online/adminloginlocations')
      .then(res => {
        setFullSuggestions(res.data);  // Set full list of suggestions on fetch
      })
      .catch(err => console.log(err));
  }, []);
  const handleInput = (event) => {
    const {name, value } = event.target;
    

    setValues(prev => ({ ...prev, [name]: value }));

    if (name === 'loginLocation') {
      setShowSuggestions(true);
      if (value.trim() === '') {
        setCountrySuggestions([]);
      } else {
        setCountrySuggestions(
          fullSuggestions.filter(location =>
            location.toLowerCase().includes(value.toLowerCase())
          )
        );
      }
    }
  };
  

  const handleSuggestionClick = (suggestion) => {
    setValues(prev => ({ ...prev, loginLocation: suggestion }));
    setShowSuggestions(false);
    setLocation(suggestion); // Assuming setLocation is a function to update the location state

  };

  // const handleDistrictChange = (event) => {
  //   setDistrict(event.target.value);
  // };

  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    setDistrict(selectedDistrict);
    // Reset state and district options when country changes
   
    setArea('');
    // Fetch states based on selected country
    fetchArea(selectedDistrict);
  };

  const handleCompanyChange = (event) => {
    setBusinessName(event.target.value);
  };

  const handleAreaChange = (event) => {
    setArea(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleBusinessIDChange = (event) => {
    setBusinessID(event.target.value);
  };

  const handleDateChange = (date) => {
    // Use UTC date to handle timezone offsets correctly
    setSelectedDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
  };
  
  const handleFromDateChange = (date) => {
    setFromDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
  };
  
  const handleToDateChange = (date) => {
    setToDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
  };


  const filterBusinessesByToday = () => {
    const today = new Date();
    const filteredBusinesses = businesses.filter(business => {
      const businessDate = new Date(business.dateofnextmeeting);
      return businessDate.getDate() === today.getDate() &&
             businessDate.getMonth() === today.getMonth() &&
             businessDate.getFullYear() === today.getFullYear();
    });
    return filteredBusinesses;
  };

  const handleSearch = async (selectedProduct = product) => {
    try {
      const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : '';
      const formattedFromDate = fromDate ? fromDate.toISOString().split('T')[0] : '';
      const formattedToDate = toDate ? toDate.toISOString().split('T')[0] : '';
      

        const queryParams = {
            loginlocation: username,
            spanco,
            product: selectedProduct, // Use selectedProduct parameter
            Location,
            selectedDate: formattedDate,
            id: BusinessID.trim(), // Ensure no leading/trailing spaces interfere
            Country,
            State,
            District,
            Area,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
            PhoneNumber: PhoneNumber.trim(), // Ensure no leading/trailing spaces interfere
            BusinessName: BusinessName.trim() // Ensure no leading/trailing spaces interfere
        };

        const queryString = new URLSearchParams(queryParams).toString();

        const response = await axios.get(`https://appbackend.vpserver.online/adminfollow?${queryString}`);
        setBusinesses(response.data);
        fetchProductCounts();
        // Assuming you have a function to fetch product countshh
        const productCounts = await fetchProductCounts(queryParams);
        setProductCounts(productCounts);
    } catch (error) {
        console.error('Error fetching business names:', error);
    }
};

  const handleOpenModal = () => {
    setModalIsOpen(true);
    
    const spancoCounts = {
      Suspect: 0,
      Prospect: 0,
      Approach: 0,
      Negotiation: 0,
      Close: 0,
      Order: 0,
      Omission: 0
    };
  
    businesses.forEach(business => {
      spancoCounts[business.spanco]++;
    });
  
    setSpancoCount(spancoCounts);
  };
  
  const handleCloseModal = () => {
    setModalIsOpen(false);
    setInputFieldsVisible();

  };

  useEffect(() => {
    const toggleInputFieldsAndUpdateSpancoCount = () => {
      const counts = {};
      businesses.forEach(business => {
        if (business.spanco in counts) {
          counts[business.spanco]++;
        } else {
          counts[business.spanco] = 1;
        }
      });
      setSpancoCount(counts);
    };
  
    toggleInputFieldsAndUpdateSpancoCount();
  }, [businesses]);
  

  const toggleInputFields = () => {
    setInputFieldsVisible(!inputFieldsVisible);
    const spancoCounts = {};
    businesses.forEach(business => {
      if (business.spanco in spancoCounts) {
        spancoCounts[business.spanco]++;
      } else {
        spancoCounts[business.spanco] = 1;
      }
    });
    setSpancoCount(spancoCounts);
  };

const handleFilterClick = () => {
    setFilterClicked(true);
    setInputFieldsVisible(true);
    handleSearch();
  };

  
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setInputFieldsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchLoginLocations = async () => {
      try {
        const response = await axios.get('https://appbackend.vpserver.online/adminloginlocations');
        setLocationOptions(response.data);
      } catch (error) {
        console.error('Error fetching login locations:', error);
      }
    };
    fetchLoginLocations();
  }, []);


  

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get('https://appbackend.vpserver.online/admincountires');
        setCountryOptions(response.data);
      } catch (error) {
        console.error('Error fetching login locations:', error);
      }
    };
    fetchCountry();
  }, []);


  
  // useEffect(() => {
  //   const fetchState = async (selectedCountry) => {
  //     try {
  //       const response = await axios.get(`https://appbackend.vpserver.online/adminstates?country=${selectedCountry}`);
  //       setStateOptions(response.data);
  //     } catch (error) {
  //       console.error('Error fetching login locations:', error);
  //     }
  //   };
  //   fetchState();
  // }, []);


  const fetchStates = async (selectedCountry) => {
    try {
      const response = await axios.get(`https://appbackend.vpserver.online/adminstates?country=${selectedCountry}`);
      setStateOptions(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };


  const fetchDistrict = async (selectedState) => {
    try {
      const response = await axios.get(`https://appbackend.vpserver.online/admindistricts?state=${selectedState}`);
      setDistrictOptions(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  // useEffect(() => {
  //   const fetchDistrict = async () => {
  //     try {
  //       const response = await axios.get('https://appbackend.vpserver.online/admindistricts');
  //       setDistrictOptions(response.data);
  //     } catch (error) {
  //       console.error('Error fetching login locations:', error);
  //     }
  //   };
  //   fetchDistrict();
  // }, []);

  

  

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get('https://appbackend.vpserver.online/admincompany');
        setCompanyOptions(response.data);
      } catch (error) {
        console.error('Error fetching login locations:', error);
      }
    };
    fetchCompany();
  }, []);




  const fetchArea = async (selectedDistrict) => {
    try {
      const response = await axios.get(`https://appbackend.vpserver.online/adminareas?district=${selectedDistrict}`);
      setAreaOptions(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };
  const handleLogout = () => {
    sessionStorage.removeItem('sessionId'); // Clear the session ID from sessionStorage
    navigate('/'); // Redirect to login page
  };
  useEffect(() => {
    handleSearch();
  }, []);
  const handleClearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedDate(null);
    setCountry("");
    setState("");
    setDistrict("");
    setArea("");
    setLocation("");
    setValues({
      ...values,
      loginLocation: ''  // Reset loginLocation
    });
  };
  

  const fetchProductCounts = async (queryParamsCount) => {
    try {
      const queryStringCount = new URLSearchParams(queryParamsCount).toString();
      const response = await axios.get(`https://appbackend.vpserver.online/adminproductcounts?${queryStringCount}`);
      return response.data; // Return the response data to be handled in handleSearch
    } catch (error) {
      console.error('Error fetching product counts:', error);
      throw error; // Rethrow the error to be handled in handleSearch
    }
  };

  const handleProductClick = (productName) => {
    setProduct(productName);
    handleSearch(productName);
  };

  const handleSpancoChange = (spancoValue) => {
    setSpanco(spancoValue);
  };

  const handleExportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'business_data_detailed_summary';
  
    const spancoCounts = {};
    businesses.forEach(business => {
      spancoCounts[business.spanco] = (spancoCounts[business.spanco] || 0) + 1;
    });
  
    const summaryItems = businesses.map(business => ({
      ...business,
      'Spanco Count': spancoCounts[business.spanco] > 1 ? `${business.spanco} (${spancoCounts[business.spanco]})` : business.spanco
    }));
  
    const ws = XLSX.utils.json_to_sheet(summaryItems);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };
  const CustomInputF = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <button className="custom-input mrg Date" onClick={onClick} ref={ref}>
      {value || 'From'} <FontAwesomeIcon icon={faCalendarAlt} />
    </button>
  ));
  const CustomInputT = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <button className="custom-input mrg Date" onClick={onClick} ref={ref}>
      {value || 'To'} <FontAwesomeIcon icon={faCalendarAlt} />
    </button>
  ));
  const CustomInputN = React.forwardRef(({ value, onClick, placeholder }, ref) => (
    <button className="custom-input mrg Date" onClick={onClick} ref={ref}>
      {value || ''} <FontAwesomeIcon icon={faCalendarAlt} />
    </button>
  ));
  return (
    <>
    <div className='admin-header'>
    <img src={myImage} alt="My Image" className="admin-panel-image" />

    </div>
    <div className="centered">
      <h1 className='vp'>
          <span>
          </span>
        </h1>

        <div className="product-buttons">
        {Object.entries(productCounts).map(([productName, count]) => (
          <button className='AfollowbuttonP' key={productName} onClick={() => handleProductClick(productName)}>
            {productName} - {count}
          </button>
        ))}
        <button className='AfollowbuttonP' onClick={() => handleProductClick("")}>All</button>
      </div>

      <div className='intext'>SPANCOO</div>
      <div className="input_AdminFollow">
        <button className={spanco === "" ? "selected" : ""} onClick={() => {handleSearch(); setSpanco("");}} >
          All {spancoCount[""] === undefined ? "" : `${spancoCount[""]}`}
        </button>
        <button className={`suspect ${spanco === "Suspect" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Suspect");}}>
          S {spancoCount["Suspect"] === undefined ? "(0)" : `(${spancoCount["Suspect"]})`}
        </button>
        <button className={`prospect ${spanco === "Prospect" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Prospect");}}>
          P {spancoCount["Prospect"] === undefined ? "(0)" : `(${spancoCount["Prospect"]})`}
        </button>
        <button className={`approach ${spanco === "Approach" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Approach");}}>
          A {spancoCount["Approach"] === undefined ? "(0)" : `(${spancoCount["Approach"]})`}
        </button>
        <button className={`negotiation ${spanco === "Negotiation" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Negotiation");}}>
          N {spancoCount["Negotiation"] === undefined ? "(0)" : `(${spancoCount["Negotiation"]})`}
        </button>
        <button className={`closed ${spanco === "Close" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Close");}}>
          C {spancoCount["Close"] === undefined ? "(0)" : `(${spancoCount["Close"]})`}
        </button>
        <button className={`order ${spanco === "Order" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Order");}}>
          O {spancoCount["Order"] === undefined ? "(0)" : `(${spancoCount["Order"]})`}
        </button>
        <button className={`omission ${spanco === "Omission" ? "selected" : ""}`} onClick={() => {handleSearch(); setSpanco("Omission");}}>
          O {spancoCount["Omission"] === undefined ? "(0)" : `(${spancoCount["Omission"]})`}
        </button>
      </div>
      <div>


        <input className="input_AdminFollow" type="text" value={BusinessName} placeholder='Enter Business Name' onChange={(e) => setBusinessName(e.target.value)}>
        </input>
        <input className="input_AdminFollow" type="text" placeholder='Enter Business ID' value={BusinessID} onChange={(e) => setBusinessID(e.target.value)} />
        <input className="input_AdminFollow" type="text" placeholder='Enter Phone Number' value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      </div>
      <button className='inlabel Afollowbuttonsearch' onClick={() => {handleSearch(); handleCloseModal();}}>Search</button>

  
  <div className='inbody'>
      <div className='inbtn'>
      <Modal isOpen={inputFieldsVisible} onRequestClose={handleCloseModal} className="modal-overlay">
  <div className="modal-content">
    <div className="modal-header">
      <h2 className="modal-title">Filter Options</h2>
      <button onClick={handleCloseModal} className="modal-close-button">&times;</button>
    </div>
    <div className="modal-body">
      <div className="date-picker-container">
        <DatePicker
          selected={fromDate}
          onChange={handleFromDateChange}
          customInput={<CustomInputF className="CustomInputF" placeholder="FD" />}
          popperPlacement="bottom-start"
        />
        <DatePicker
          selected={toDate}
          onChange={handleToDateChange}
          customInput={<CustomInputT className="CustomInputT" placeholder="Select Date" />}
          popperPlacement="bottom"
        />
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          customInput={<CustomInputN className="CustomInputN" placeholder="DON" />}
          popperPlacement="bottom-start"
        />
      </div>

      <label className='modalL'>Country</label>
      <select value={Country} className="" onChange={handleCountryChange}>
        <option value="">All</option>
        {countryOptions
          .filter(option => option !== 'admin')
          .map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
      </select>

      <label className='modalL'>State</label>
      <select className="" value={State} onChange={handleStateChange}>
        <option value="">All</option>
        {stateOptions
          .filter(option => option !== 'admin')
          .map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
      </select>

      <label className='modalL'>District</label>
      <select className="" value={District} onChange={handleDistrictChange}>
        <option value="">All</option>
        {districtOptions
          .filter(option => option !== 'admin')
          .map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
      </select>

      <label className='modalL'>Area</label>
      <select className="" value={Area} onChange={handleAreaChange}>
        <option value="">All</option>
        {areaOptions
          .filter(option => option !== 'admin')
          .map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
      </select>

      <label className='modalL'>Login Location</label>
       <input
                type='text'
                name='loginLocation'
                value={values.loginLocation}
                onChange={handleInput}
                className='form-control rounded-15'
                placeholder='Enter Login Location'
              />
              {showSuggestions && countrySuggestions.length > 0 && (
                <ul className="suggestions">
                  {countrySuggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion)}>{suggestion}</li>
                  ))}
                </ul>
              )}
              {errors.loginLocation && <span className='text-danger'>{errors.loginLocation}</span>}
    
    </div>

    <div className="modal-footer">
      <button className='apply-button' onClick={() => { handleSearch(); handleCloseModal(); }}>Apply</button>
      <button className='clear-button' onClick={handleClearFilters}>Clear</button>
      <button className='close-button' onClick={handleCloseModal}>Close</button>
    </div>
  </div>
  {filterClicked && <button className='filter-button' onClick={() => setInputFieldsVisible(false)}>Filter</button>}
</Modal>



        <button className='Afollowbuttonshow' onClick={toggleInputFields}>
          <FontAwesomeIcon icon={faFilter} /> Show Filters
        </button>
      </div>
    </div> 
      <BusinessList onBusinessClick={handleBusinessClick} businesses={businesses} />
      {selectedBusiness && <BusinessDetails selectedBusiness={selectedBusiness} />}
      {/* Modal */}
      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} className="SpancoModal">
  <h2>Spanco Counts</h2>
  <div>
    {Object.entries(spancoCount).map(([spancoStage, count]) => (
      <p key={spancoStage}>{spancoStage}: {count}</p>
    ))}
  </div>
  <button className="close-btn" onClick={handleCloseModal}>Close</button>
</Modal>
</div>
    </>
  );
}


export default Rep;